import { useSelector } from 'react-redux';
import { getUi } from '@ducks/ui/selectors';
import loadable from '@loadable/component';
import useUser from '@/hooks/useUser';
import { useUserAccess } from '@/hooks/useUserAccess';
import { getRegistrationFromNewsCookie } from '@/utilities/cookies';
import { useLocation } from '@/hooks/useLocation';
import {
  ROUTE_PARTNER_AVANZA_CLAIM,
  ROUTE_PARTNER_AVANZA_ERROR,
  ROUTE_PARTNER_CLAIM,
  ROUTE_PARTNER_ERROR,
} from '@/constants/routes';
import { useModalManagement } from '@/components/ModalManagement';
import { useEffect } from 'react';

const MODAL_ID = 'onboardingModal';

const OnboardingContent = loadable(
  () => import('@/components/Onboarding/components/OnboardingContent'),
  {
    resolveComponent: (c) => c.OnboardingContent,
    ssr: false,
  }
);

export const Onboarding = () => {
  const ui = useSelector(getUi);
  const { data: user } = useUser();
  const { data: access } = useUserAccess();
  const location = useLocation();
  const { isShowingModal, addModalIfEmpty, removeModal } =
    useModalManagement(MODAL_ID);

  const isRegistrationFromNews =
    user.loggedIn && getRegistrationFromNewsCookie();
  const showModal =
    ((ui.showOnboarding &&
      user.loggedIn &&
      (access.planType === 'premium' || access.planType === 'free') &&
      user.billing.status === 'trialing' &&
      RUNTIME_ENV !== 'server') ||
      isRegistrationFromNews) &&
    // Don't show the onboarding modal when a user first joins by trying to claim a broker linkage
    ![
      ROUTE_PARTNER_CLAIM,
      ROUTE_PARTNER_ERROR,
      ROUTE_PARTNER_AVANZA_CLAIM,
      ROUTE_PARTNER_AVANZA_ERROR,
    ].includes(location.type);

  useEffect(() => {
    if (showModal) {
      addModalIfEmpty();
    } else {
      removeModal();
    }
  }, [addModalIfEmpty, removeModal, showModal]);

  if (!isShowingModal) {
    return null;
  }

  return <OnboardingContent />;
};
