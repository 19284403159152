import { BarStackHorizontal } from '@visx/shape';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Title, Value } from './styled';

import { useTheme } from '@simplywallst/ui-core';
import { Keys } from '../../utils';
export interface OwnershipEntity {
  Institutions?: number;
  'Public Companies'?: number;
  'Private Companies'?: number;
  'Individual Insiders'?: number;
  'Company Controlled Foundation'?: number;
  'Employee Share Scheme'?: number;
  'State or Government'?: number;
  'Hedge Funds'?: number;
  'VC/PE Firms'?: number;
  'Sovereign Wealth Funds'?: number;
  'General Public'?: number;
}

export interface OwnershipValues {
  shares: string;
  percent: string;
}

export interface OwnershipDataFormatted {
  Institutions?: OwnershipValues;
  'Public Companies'?: OwnershipValues;
  'Private Companies'?: OwnershipValues;
  'Individual Insiders'?: OwnershipValues;
  'Company Controlled Foundation'?: OwnershipValues;
  'Employee Share Scheme'?: OwnershipValues;
  'State or Government'?: OwnershipValues;
  'Hedge Funds'?: OwnershipValues;
  'VC/PE Firms'?: OwnershipValues;
  'Sovereign Wealth Funds'?: OwnershipValues;
  'General Public'?: OwnershipValues;
}

interface Props {
  data: Partial<OwnershipEntity>[];
  dataFormatted: OwnershipDataFormatted;
  totalOwnedShares: number;
  titleLabel?: (key: Keys) => string;
  sharesLabel?: (shares?: string) => string;
}

const Ownership = ({
  data,
  dataFormatted,
  totalOwnedShares,
  titleLabel = (key) => key,
  sharesLabel = (shares) => shares || '',
}: Props) => {
  const theme = useTheme();
  const keys = Object.values(Keys)
    .filter((n) => data[0][n] !== undefined)
    .sort((a, b) => (data[0][a] || 0) - (data[0][b] || 0));

  const labelPadding = theme.x1spacePx / 2;
  const baseLineHeight = keys.length * theme.x6spacePx;

  const yScale = scaleBand({
    domain: keys,
    range: [0, theme.x6spacePx * keys.length],
  });

  const xScale = scaleLinear({
    domain: [0, totalOwnedShares],
    range: [0, 1],
  });

  const color = scaleOrdinal({
    domain: keys,
    range: [
      theme.color.chart01,
      theme.color.chart02,
      theme.color.chart03,
      theme.color.chart04,
      theme.color.chart05,
      theme.color.chart06,
    ],
  });

  return (
    <div>
      <svg
        width="100%"
        height={baseLineHeight + theme.x6spacePx}
        shapeRendering="crispEdges"
      >
        <BarStackHorizontal
          data={data}
          keys={keys}
          y={() => '0' as (typeof Keys)[keyof typeof Keys]}
          xScale={xScale}
          yScale={yScale}
          color={color}
        >
          {(barStacks) => {
            return barStacks.map((barStack, i) => {
              return barStack.bars
                .filter((bar) => bar.width > 0)
                .map(({ key, x, width, color }) => {
                  const isLast = key === keys[keys.length - 1];
                  const barX = x * 100;
                  const labelX = barX + (isLast ? width * 100 : 0);
                  const labelPaddingX = isLast ? -labelPadding : labelPadding;
                  const formatted = dataFormatted[key];
                  return (
                    <g key={`ownership-bar-${key}`}>
                      <Title
                        x={`${labelX}%`}
                        y={i * theme.x6spacePx}
                        dx={labelPaddingX}
                        dy={labelPadding}
                        textAnchor={isLast ? 'end' : 'start'}
                        color={color}
                      >
                        <tspan
                          data-cy-id={`chart-ownership-title-${key}`}
                          dominantBaseline="hanging"
                        >
                          {titleLabel(key)}
                        </tspan>
                        {` `}
                        <tspan
                          data-cy-id={`chart-ownership-percentage-${key}`}
                          dominantBaseline="hanging"
                        >
                          {formatted ? formatted.percent : ``}
                        </tspan>
                      </Title>
                      <Value
                        x={`${labelX}%`}
                        y={i * theme.x6spacePx}
                        dx={labelPaddingX}
                        dy={24}
                        textAnchor={isLast ? 'end' : 'start'}
                        dominantBaseline="hanging"
                        data-cy-id={`chart-ownership-shares-${key}`}
                      >
                        {sharesLabel(formatted?.shares)}
                      </Value>
                      <rect
                        x={`${labelX}%`}
                        y={i * theme.x6spacePx}
                        width="1"
                        height={
                          (keys.length - i) * theme.x6spacePx + theme.x6spacePx
                        }
                        fill="white"
                        fillOpacity="0.3"
                        transform={isLast ? 'translate(-1 0)' : ''}
                      />
                      <rect
                        x={`${barX}%`}
                        y={baseLineHeight}
                        width={`${width * 100}%`}
                        height={theme.x6spacePx}
                        fill={color}
                      />
                    </g>
                  );
                });
            });
          }}
        </BarStackHorizontal>
      </svg>
    </div>
  );
};

export default Ownership;
