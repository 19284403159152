import styled from 'styled-components';

interface TitleProps {
  color: string;
}

export const Title = styled('text')<TitleProps>`
  ${({ theme }) => theme.typography.xSmallStrong}
  tspan {
    fill: ${({ theme }) => theme.color.white};
    &:last-child {
      fill: ${({ theme, color }) => color || theme.color.brand01};
    }
  }
`;

export const Value = styled('text')`
  ${({ theme }) => theme.typography.tiny}
  fill: ${({ theme }) => theme.color.whiteRGBA(0.5)};
`;
